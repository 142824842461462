









































import { Component, Vue } from 'vue-property-decorator';
import CircleButton from '@/components/CircleButton.vue';
import Modal from '@/components/Modal.vue';

import pageContent from '@/contents/abriefhistory';
import Welcome from '../../contents/abriefhistory/html/Welcome.html';

@Component({
  components: {
    CircleButton,
    Modal,
  },
})
export default class Abriefhistory extends Vue {
  public modalOpen = '';

  public welcomeText = Welcome;

  public pageContent = pageContent;

  close(id: string) {
    this.modalOpen = this.modalOpen === id ? '' : id;
  }
}
