import PageContents from '@/types/PageContents';

// import EIntroVideo from './html/EndoTower-Intro-Video.html';

import Karlsruhe2001 from './html/Karlsruhe2001.html';
import USA2006 from './html/USA2006.html';
import HongKong2019 from './html/HongKong2019.html';
import Singapore2021 from './html/Singapore2021.html';
// import Welcome from './html/Welcome.html';

// const distanceX = 7.5;

// const videoModalSize = {
//   height: 'calc(315px + 4rem)',
//   width: 'calc(560px + 4rem)',
// };

const pageContent: PageContents[] = [
  // {
  //   button: {
  //     top: '23%',
  //     left: '72%',
  //     type: 'video',
  //     content: ' Intracs',
  //     textAlign: 'left',
  //   },
  //   modal: {
  //     ...videoModalSize,
  //     content: EIntroVideo,
  //   },
  // },

  { // 2001 Karlsruhe
    button: {
      top: '22%',
      left: '56%',
      type: 'open',
    },
    modal: {
      content: Karlsruhe2001,
    },
  },

  { // 2006 USA
    button: {
      top: '29%',
      left: '26%',
      type: 'open',
      content: '',
    },
    modal: {
      content: USA2006,
    },
  },

  { // 2019 Hong-Kong
    button: {
      top: '32%',
      left: '80%',
      type: 'open',
      content: '',
    },
    modal: {
      content: HongKong2019,
    },
  },

  { // 2021 Singapore
    button: {
      top: '48%',
      left: '82.5%',
      type: 'open',
    },
    modal: {
      content: Singapore2021,
    },
  },
];

export default pageContent;
